<template>
  <div class="security-page">
    <v-container fluid fill-height class="pa-0">
      <v-row>
      <v-toolbar flat>
          <v-toolbar-title> {{ $t("AccountSecurity") }} </v-toolbar-title>
          <v-spacer></v-spacer>
          
          <slot name="header-actions" />
        </v-toolbar>
      </v-row>
      <v-row>
        <v-col>
          <div style="background-color=#EEF0EB;max-width:500px;text-align:center" class="mx-auto">
            <div style="margin:20px; color:#C41920;font-size:24px;font-weight:900">
              {{ $t("ResetPassword") }}
            </div>
            <v-form v-model="valid" ref="change-password-form" lazy-validation>
                    <v-text-field
                      v-model="credentials.old_password"
                      solo
                      prepend-icon="mdi-lock"
                      name="old_password"
                      :label="$t('Password')"
                      type="password"
                      :rules="[filterRules.required, filterRules.min8]"
                    ></v-text-field>
                    <v-text-field
                      v-model="credentials.new_password"
                      prepend-icon="mdi-lock"
                      name="new_password"
                      :label="$t('NewPassword')"
                      type="password"
                      solo
                      :rules="[filterRules.required, filterRules.min8]"
                    ></v-text-field>
                    <v-text-field
                      v-model="credentials.password_confirmation"
                      prepend-icon="mdi-lock"
                      name="password_confirmation"
                      :label="$t('PasswordConfirmation')"
                      type="password"
                      
                      solo
                      :rules="[
                        filterRules.required,
                        filterRules.passwordConfirmation,
                        filterRules.min8,
                      ]"
                    ></v-text-field>
                    <v-btn
                      :loading="isSubmitting"
                      dark
                      color="dark"
                      @click="attemptSubmit"
                    >
                      {{ $t("Submit") }}
                    </v-btn>
              </v-form>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: "AccountSecurity",
  components: {},
  data() {
    return {
      valid: false,
      isSubmitting: false,
      credentials: {
        old_password: null,
        new_password: null,
        password_confirmation: null,
      },
    };
  },
  computed: {
    filterRules() {
      let rules = Vue.formHelper.filterRules();
      return {
        ...rules,
        passwordConfirmation: (value) => {
          if (value && value !== this.credentials.new_password) {
            return this.$t("PasswordDontMatch");
          }
          return true;
        },
      };
    },
  },
  methods: {
    attemptSubmit() {
      // to validate if none of the inputs were changed
      let validation = this.$refs["change-password-form"].validate();

      if (this.valid && validation) {
        this.isSubmitting = true;

        this.$store
          .dispatch("auth/changePassword", this.credentials)
          .then((success) => {
              this.$root.$emit("notify-success", this.$t('PasswordChanged'))
          })
          .catch((failure) => {
            if (failure.response) {
              this.$root.$emit(
                "notify-error",
                this.$t(failure.response.data.error.message)
              );
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.isSubmitting = false;
            }, 500);
          });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.security-page {
  min-height: 80vh;
}
.profile-form {
  min-height: 80vh;
}
</style>